import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import resultsPiece from "assets/results-piece.svg";
import {
  getDecisionTreeResultD1,
  getDecisionTreeResultD2,
  getDecisionTreeResultD3,
  getDecisionTreeResultD4,
} from "./utils/functions";
import { useTranslation } from "react-i18next";
import { TextWithInput } from "components/TextWithInput";
import ReactLoading from "react-loading";
import { PiecesAuterationDataProps } from "types/pieces";
import { setUpdateValues } from "api/piece";
import { callToast } from "utils/toast";

export const ResultsPage: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    a1: 0,
    a2: 0,
    a3: 0,
    a4: 0,
  });
  const [valuesChangeReason, setValuesChangeReason] = useState({
    a1: "",
    a2: "",
    a3: "",
    a4: "",
  });
  const [isEditingValues, setIsEditingValues] = useState({
    a1: false,
    a2: false,
    a3: false,
    a4: false,
  });

  useEffect(() => {
    if (!location.state.piece) {
      navigate("/dashboard");
    }
  }, [location, navigate]);

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  const someValuesIsEditable = Array.from(Object.values(isEditingValues)).some(
    (value) => value
  );

  useEffect(() => {
    setValues({
      a1: Number(
        getDecisionTreeResultD1({
          d1: location.state.piece[0].desvio_raio_do_esferico,
          d2: location.state.piece[0].desvio_altura_da_divisoria,
          temperature: location.state.piece[0].temperatura_ambiente,
        }).replace(",", ".")
      ),
      a2: Number(
        getDecisionTreeResultD2({
          climatized: location.state.piece[0].peca_climatizada,
          ootd2: location.state.piece[0].fora_de_tolerancia_altura_da_divisoria,
          d2: location.state.piece[0].desvio_altura_da_divisoria,
        }).replace(",", ".")
      ),
      a3: Number(
        getDecisionTreeResultD3({
          climatized: location.state.piece[0].peca_climatizada,
          d3: location.state.piece[0].desvio_comprimento_face_b,
        }).replace(",", ".")
      ),
      a4: Number(
        getDecisionTreeResultD4({
          d4: location.state.piece[0].desvio_comprimento_face_oposta,
          ootd4:
            location.state.piece[0].fora_de_tolerancia_comprimento_face_oposta,
        }).replace(",", ".")
      ),
    });
  }, [location]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const onChanveValueReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setValuesChangeReason((prev) => ({ ...prev, [name]: value }));
  };

  const openReason = (name: string) => {
    setValuesChangeReason((prev) => ({ ...prev, [name]: "" }));
    setIsEditingValues((prev) => ({ ...prev, [name]: true }));
  };

  const handleSaveUpdateValues = async () => {
    setLoading(true);
    const getUserId = await JSON.parse(
      localStorage.getItem("user:smart-dc")?.replace("id:", "id") || "{}"
    );
    const user = getUserId.id;

    const sendValues: PiecesAuterationDataProps = {
      fn: "create",
      idPeca: location.state.piece[0].id_peca,
      idUsuario: user,
      desvio_raio_do_esferico: String(values.a1),
      motivo_raio_do_esferico: valuesChangeReason.a1,
      altura_da_divisoria: String(values.a2),
      motivo_altura_da_divisoria: valuesChangeReason.a2,
      comprimento_face_b: String(values.a3),
      motivo_comprimento_face_b: valuesChangeReason.a3,
      comprimento_face_oposta_b: String(values.a4),
      motivo_comprimento_face_oposta_b: valuesChangeReason.a4,
    };

    try {
      await setUpdateValues(sendValues);
      callToast("Valores atualizados com sucesso!", "success");
    } catch (error) {
      callToast("Erro ao atualizar valores!", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full flex items-center justify-center">
      <div className="*:p-8 w-full max-w-7xl">
        <h1 className="text-3xl font-bold text-blue-900 text-center">
          {t("adjustsOnProgram")}
        </h1>
        <div className="flex flex-row justify-between gap-12">
          <div className="w-1/2 flex items-center justify-center">
            <img src={resultsPiece} alt="Resultados" />
          </div>
          <div className="w-1/2 flex flex-col my-auto gap-6">
            <div className="flex row items-center justify-start gap-4 w-full">
              <p className="text-2xl w-fit text-nowrap">
                <span className="font-bold">A1 - </span>
                {t("d1Title").split(" - ")[1]}:{" "}
              </p>
              <TextWithInput
                inputType="number"
                initialState={false}
                onChange={onChange}
                value={values.a1}
                name="a1"
                onDoubleClick={() => openReason("a1")}
                spanExtraClasses="text-2xl font-bold"
              />
            </div>
            {isEditingValues.a1 && (
              <textarea
                value={valuesChangeReason.a1}
                onChange={onChanveValueReason}
                placeholder="Informe o motivo da alteração"
                className="w-full h-16 p-2 border border-gray-300 rounded-lg -mt-4 text-base"
                name="a1"
              />
            )}
            <div className="flex row items-center justify-start gap-4 w-full">
              <p className="text-2xl w-fit text-nowrap">
                <span className="font-bold">A2 - </span>
                {t("d2Title").split(" - ")[1]}:{" "}
              </p>
              <TextWithInput
                inputType="number"
                initialState={false}
                onChange={onChange}
                value={values.a2}
                name="a2"
                onDoubleClick={() => openReason("a2")}
                spanExtraClasses="text-2xl font-bold"
              />
            </div>
            {isEditingValues.a2 && (
              <textarea
                value={valuesChangeReason.a2}
                onChange={onChanveValueReason}
                placeholder="Informe o motivo da alteração"
                className="w-full h-16 p-2 border border-gray-300 rounded-lg -mt-4 text-base"
                name="a2"
              />
            )}
            <div className="flex row items-center justify-start gap-4 w-full">
              <p className="text-2xl w-fit text-nowrap">
                <span className="font-bold">A3 - </span>
                {t("d3Title").split(" - ")[1]}:{" "}
              </p>
              <TextWithInput
                inputType="number"
                initialState={false}
                onChange={onChange}
                value={values.a3}
                name="a3"
                onDoubleClick={() => openReason("a3")}
                spanExtraClasses="text-2xl font-bold"
              />
            </div>
            {isEditingValues.a3 && (
              <textarea
                value={valuesChangeReason.a3}
                onChange={onChanveValueReason}
                placeholder="Informe o motivo da alteração"
                className="w-full h-16 p-2 border border-gray-300 rounded-lg -mt-4 text-base"
                name="a3"
              />
            )}
            <div className="flex row items-center justify-start gap-4 w-full">
              <p className="text-2xl w-fit text-nowrap">
                <span className="font-bold">A4 - </span>
                {t("d4Title").split(" - ")[1]}:{" "}
              </p>
              <TextWithInput
                inputType="number"
                initialState={false}
                onChange={onChange}
                value={values.a4}
                name="a4"
                onDoubleClick={() => openReason("a4")}
                spanExtraClasses="text-2xl font-bold"
              />
            </div>
            {isEditingValues.a4 && (
              <textarea
                value={valuesChangeReason.a4}
                onChange={onChanveValueReason}
                placeholder="Informe o motivo da alteração"
                className="w-full h-16 p-2 border border-gray-300 rounded-lg -mt-4 text-base"
                name="a4"
              />
            )}
            <p className="text-xs font-bold">
              *Caso tenha feito configurações próprias, clique duas vezes sobre
              o valor para editar.
            </p>
            {someValuesIsEditable && (
              <button
                onClick={handleSaveUpdateValues}
                className="bg-blue-900 text-white font-bold min-h-10 !px-4 !py-2 rounded-lg mt-8 min-w-36 hover:brightness-90 transition-all duration-700 w-fit ml-auto"
              >
                {loading ? (
                  <ReactLoading
                    className="mx-auto"
                    type="spin"
                    color="#ffffff"
                    height={20}
                    width={20}
                  />
                ) : (
                  t("saveButton")
                )}
              </button>
            )}
          </div>
        </div>
        <button
          className="bg-blue-900 text-white font-bold !px-4 !py-2 rounded-lg mt-8 ml-24 min-w-36 hover:brightness-90 transition-all duration-700"
          onClick={handleBackToDashboard}
        >
          {t("backToDashboard")}
        </button>
      </div>
    </div>
  );
};
